<script setup>
import router from "@/router";
import { ref, onMounted } from "vue";
import { onAuthStateChanged } from "firebase/auth";
import AppBanner from "@/components/AppBanner.vue";
import AppLoader from "@/components/AppLoader.vue";
import { convertToFrenchDate } from "@/utils/date";
import StudioHeader from "@/components/studio/StudioHeader.vue";
import { useStudioStore } from "@/stores/studio";

const store = useStudioStore();
const { auth } = store;

const userGames = ref([]);
const isLoading = ref(false);

async function fetchUserGames() {
	isLoading.value = true;
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) throw new Error("No authentication token found");

		const url = process.env.VUE_APP_API_URL;
		const response = await fetch(`${url}/games`, {
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${token}`,
			},
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		userGames.value = await response.json();
	} catch (error) {
		console.error("Failed to fetch games:", error);
		// Consider adding user-facing error notification here
	} finally {
		isLoading.value = false;
	}
}

function handleClick(game) {
	router.push(`/studio/${game.id}`);
}

function handleCreate() {
	router.push(`/new`);
}

onMounted(() => {
	onAuthStateChanged(auth, (user) => {
		if (user) {
			fetchUserGames();
		}
	});
});
</script>

<template>
	<div class="h-dvh flex flex-col">
		<AppBanner />
		<AppLoader v-if="isLoading" message="Chargement en cours" />
		<div
			v-else
			class="relative h-full w-full flex justify-center overflow-y-auto"
		>
			<div class="w-full relative">
				<div class="p-10">
					<div class="mb-10">
						<p class="font-roboto-slab text-black-studio-500 text-xl mb-2">
							{{
								auth.currentUser?.displayName != null
									? `Bonjour inspecteur ${auth.currentUser.displayName},`
									: "Bonjour inspecteur,"
							}}
						</p>
						<StudioHeader text="Voici vos enquêtes" />
					</div>
					<div class="flex flex-wrap gap-4 mb-[136px]">
						<div
							class="w-full md:w-[442px] md:min-w-[442px] border cursor-pointer border-black-studio-400"
							v-for="game in userGames"
							:key="game.id"
							@click="handleClick(game)"
						>
							<div class="w-full">
								<div
									class="w-full flex p-6 border-b border-black-studio-400 items-center"
								>
									<p
										class="uppercase text-xs font-roboto-flex font-semibold tracking-wider text-black-studio-500 mr-6"
									>
										Avancement du dossier :
									</p>
									<button
										class="uppercase px-2 py-1 text-xs text-black-studio-300 bg-black-studio-100 tracking-wider font-semibold"
									>
										En cours
									</button>
									<div
										class="my-auto h-4 border-l border-black-studio-200 mx-4"
									></div>
									<button
										class="uppercase px-2 py-1 text-xs text-white bg-red-400 tracking-wider font-semibold"
									>
										Prêt
									</button>
								</div>
								<div class="w-full p-6">
									<div>
										<div
											class="grid grid-cols-[90%,10%] justify-between mb-2 items-center"
										>
											<p
												class="w-full font-bold font-roboto-slab text-gray-600 text-xl md:text-2xl truncate"
											>
												{{ game.name }}
											</p>
											<div class="w-full flex justify-end">
												<img src="@/assets/chevron-grey.svg" />
											</div>
										</div>
										<p class="text-sm font-roboto-slab text-gray-400 mb-1">
											{{ game.enigmas.length }}
											{{ game.enigmas.length <= 1 ? "énigme" : "énigmes" }}
										</p>
										<p class="text-sm font-roboto-slab text-gray-500">
											Créée le {{ convertToFrenchDate(game.created_at) }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fixed p-10 w-full bg-white bottom-0">
					<div
						class="w-fit px-6 py-4 m-auto justify-center flex items-center bg-black-studio-700 font-roboto-slab text-base text-white font-semibold cursor-pointer"
						@click="handleCreate()"
					>
						Créer une enquête
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
